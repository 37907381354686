body {
  font-family: 'Poppins', sans-serif;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.font-size-highest{
  font-size: 24px;

}


.font-size-second-highest{
  font-size: 20px;
  font-weight: 450;

}

.font-size-normal{
  font-size: 16px;
}
.font-size-normal-2{
  font-size: 14px;
  color: #000000c2;
}

.font-size-policy{

  color: rgba(67, 116, 240, 1) ;

}

.single-line{
  white-space: nowrap;
}

.font-size-small{
  font-size: 12px;
}


.bg-yellow-css{
  background-color: rgba(255, 204, 1, 1);
}

.font-bold-css{
  font-weight: 500;

}

.from-border{
  border-radius:5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.radio-label-custom {
  display: inline-block;
  margin-left: 2px;
}


.sign-in-box{
 
  width: 28rem;
  height: 38rem;
  padding: 2rem;
 border-radius: 10px;
 
 }
 @media (max-width: 768px) {
  
  .sign-in-box {
    width: 24rem;
    /* height: 35rem; */
    padding: 1rem;
    margin: 0,5rem;
  }
}

 
 
 .parent-box{
     height: 100vh;
     display: flex;
     align-items: center;
     justify-content: center;
     background-color: rgba(248, 245, 245, 0.16);
 }
 
 .bg-white-css{
 background-color: rgb(255, 255, 255);
 color: rgb(0, 0, 0);
 }
 
 
 .vertical-middle-css{
     vertical-align: middle;
 }
 .choose-account,
 .continue-text {
   line-height: 1; /* Adjust the line height as needed */
 }
 
 .profile-box{
     background-color: rgba(245, 246, 246, 1);
     border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    
 }
 .profile-box-another{
     background-color: fff;
     border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    
 }
 
 .button-border{
     border-radius: 5px;
     border: 1px solid #00000070;
     height: 100%;
     width: 100%;
 }

 .inline-input {
  display: inline-block;
  width: calc(33.333% - 6px); /* Adjust width as needed, accounting for margins */
  margin-right: 8px; /* Space between inputs */
}

.inline-input:last-child {
  margin-right: 0; /* Remove margin for the last input */
}

.red-text {
  color: red !important;
}

.loading-line {
  width: 100%;
  height: 5px; /* Height of the line */
  background: linear-gradient(to right, #0787f6 0%, #ffffffa3 50%, #0583f0 100%);
  background-size: 200% 100%; /* Double the width for the animation */
  animation: shift 2s infinite linear; /* Adjust the time as needed */
  border-radius: 100px;
}
.loading-line-stop {
  width: 100%;
  height: 5px; /* Height of the line */
 
  border-radius: 100px;
}

@keyframes shift {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}



.button-10 {
  padding: 10px 20px;
  /* background-color: #007bff;  */
  color: #000; /* Text color */
  /* border: none; */
  /* border-radius: 5px; */
  cursor: pointer;
  /* font-size: 16px;  */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transition for background color and transform */
  /* text-decoration: none;  */
  /* display: inline-block;  */
  outline: none; /* Removes the outline to improve aesthetics */
}

.button-10:hover,
.button-10:focus { /* Hover and focus styles */
  /* background-color: #0056b3; */
  transform: scale(1.02); /* Slightly larger on hover/focus */
  color: #000; /* Keeping text color the same, but can be changed if desired */
}


.profiles-container {
  max-height: 150px;
  overflow-y:scroll; 
  overflow-x: hidden;
}


/* Customizing scrollbar */
.profiles-container::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

.profiles-container::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background color of the scrollbar track */
}

.profiles-container::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 4px; /* Rounded corners */
}

.profiles-container::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar thumb on hover */
}

.footer {
  /* position: absolute; */
  bottom: 0;
  width: 100%;
  background-color: white; 
}
/* 
.Toastify__toast-container {
  width: auto;
  position: fixed;
  top: 0;
  left: 40%;
  transform: translateX(-50%);
} */


.gray-colour{
  color: #000000b8;
  font-size: 0.9rem;
}